import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49c45e0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    ref: "root",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.favouriteStudy(_ctx.openIncentive, $event))),
    class: _normalizeClass(["IconOnlyButton", { 'is-animated': _ctx.isAnimated }]),
    "aria-label": _ctx.wishlisted ? 'Remove from wishlist' : 'Add to wishlist'
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(["HeartIcon", _ctx.wishlisted ? 'lnr-heart-full' : 'lnr-heart'])
    }, null, 2 /* CLASS */)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}