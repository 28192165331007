import { createApp, h } from 'vue';
import App from './presentation/app.vue';
import { TSnowplow } from './interfaces/external-services.interfaces';
import GlobalsPlugin from './utils/vue-globals';
import { IEventAggregationService } from '@studyportals/student-interfaces';
import { DependencyCheckerPlugin } from '@/store/plugins/dependency-checker-plugin';

declare global {
	interface Window {
		Rollbar?: {
			error: (message: Error | string) => void;
		};
		snowplow?: TSnowplow;
		EventAggregationService: IEventAggregationService;
	}
}

void new DependencyCheckerPlugin().initialize();

const renderButtons = (): void => {
	const nodes: NodeListOf<Element> = document.querySelectorAll('.WishlistButton');

	for (const node of nodes) {
		const studyId = node.getAttribute('data-study-id');
		const buttonType = node.getAttribute('data-button-type');
		const buttonLocation = node.getAttribute('data-button-location');
		const buttonClass = node.getAttribute('data-button-class');

		const wishlistButton = createApp({
			render: () => {
				return h(App, {
					study_id: studyId,
					button_type: buttonType,
					button_location: buttonLocation,
					button_class: buttonClass,
				});
			},
		});

		wishlistButton.use(GlobalsPlugin);
		wishlistButton.mount(node);
	}

	document.dispatchEvent(new Event('wishlist_buttons_rendered'));
};

renderButtons();
document.addEventListener('wishlist_button_initialize', renderButtons);
