import { defineComponent, Component, onMounted, computed } from 'vue';
import IconOnly from '@/presentation/components/Buttons/IconOnly/IconOnly.vue';
import Floating from '@/presentation/components/Buttons/Floating/Floating.vue';
import StudyHeader from '@/presentation/components/Buttons/StudyHeader/StudyHeader.vue';
import BestFit from '@/presentation/components/Buttons/BestFit/BestFit.vue';
import store from '@/store';

export default defineComponent({
	components: {
		IconOnly: IconOnly as Component,
		Floating: Floating as Component,
		StudyHeader: StudyHeader as Component,
		BestFit: BestFit as Component,
	},
	props: {
		study_id: String,
		button_type: String,
		button_location: String,
		button_class: String,
	},
	setup: (props) => {
		return {
			isReady: computed((): boolean => store.getters.isReady()),
			study_id: props.study_id,
			button_type: props.button_type,
			button_location: props.button_location,
			button_class: props.button_class,
		};
	},
});
