import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d2782cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "ButtonText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.favouriteStudy(true))),
    class: _normalizeClass([{ OffCanvas: _ctx.offCanvas, 'is-animated': _ctx.isAnimated }, "FloatingWishlistButton"]),
    type: "button",
    "aria-label": _ctx.wishlisted ? 'Remove from wishlist' : 'Add to wishlist'
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(["HeartIcon", _ctx.wishlisted ? 'lnr-heart-full' : 'lnr-heart'])
    }, null, 2 /* CLASS */),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.wishlisted ? 'Added to wishlist' : 'Add to wishlist'), 1 /* TEXT */)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}