import WishlistTracking from './tracking';
import { IEventAggregationService, IWishlistService } from '@studyportals/student-interfaces';

const wishlistTracking = new WishlistTracking();
const wishlistService: IWishlistService = {} as IWishlistService;
const eventAggregationService: IEventAggregationService = {} as IEventAggregationService;

export default {
	eventAggregationService,
	wishlistTracking,
	wishlistService,
};
