import {
	IEventAggregationService,
	ISessionService,
	ISubscriber,
	SessionCreatedEvent,
	SessionServiceReadyEvent,
} from '@studyportals/student-interfaces';
import store from '@/store/index';
import globals from '@/utils/globals';

export class HandleLoggedInState implements ISubscriber<SessionServiceReadyEvent | SessionCreatedEvent> {
	get eventAggregationService(): IEventAggregationService {
		return globals.eventAggregationService;
	}

	public async notify(event: SessionServiceReadyEvent | SessionCreatedEvent): Promise<void> {
		if (event.eventType === SessionServiceReadyEvent.EventType) {
			const _event = event as SessionServiceReadyEvent;
			await this.onSessionServiceReady(_event.sessionService);
		}

		if (event.eventType === SessionCreatedEvent.EventType) {
			const _event = event as SessionCreatedEvent;
			store.actions.setLoggedInState(_event.session !== null);
		}
	}

	public initiate(): void {
		this.eventAggregationService.subscribeTo(SessionServiceReadyEvent.EventType, this, true);
		this.eventAggregationService.subscribeTo(SessionCreatedEvent.EventType, this);
	}

	private async onSessionServiceReady(sessionService: ISessionService): Promise<void> {
		const loggedInState = (await sessionService.getSession()) !== null;
		store.actions.setLoggedInState(loggedInState);
	}
}
